import styled, { css } from 'styled-components'
import Media from './Media'
import { FancyLinks } from './RootStyle'

export const HeadingSize = css`
  h1 {
    padding: 0;
    font-size: 220%;
  }

  h2 {
    font-size: 150%;
    padding: 2rem 0 0.7rem 0;
  }

  h3 {
    font-size: 120%;
  }

  ${Media.phone`
    h1 {
      font-size: 160%;
      letter-spacing: -0.1px;
    }
    h2 {
      font-size: 130%;
    }
    h3 {
      font-size: 110%;
    }
  `}
`

const Content = styled.div`
  width: 100%;
  max-width: 100%;
  ${FancyLinks}
  ${HeadingSize}

  h3,
  h4,
  h5,
  h6 {
    padding: 1.5rem 0 0.7rem 0;

    code,
    em {
      font-family: ${({ theme }) => theme.headerFont};
      background: #ededed;
      font-style: normal;
      padding: 0 0.3rem;
      border-radius: 0.2rem;
    }
  }

  p {
    padding: 0.7rem 0;
    line-height: 150%;
  }
  .svgContainer {
    position: relative;
    display: flex;
    flex-flow: column;
    align-items: center;
    align-content: center;

    img {
      position: relative;
      width: 100%;
      display: block;
      border: 0;
    }

    a {
      border: none;
      position: relative;

      :after {
        content: '☝ Click image to enlarge';
        position: relative;
        font-size: 80%;
        border: 1px dotted gray;
        padding: 0.1rem 1rem;
      }

      transition: all ease 0.6s;
    }
    margin-bottom: 2rem;
  }
  ul,
  ol {
    padding-top: 0.7rem;
    padding-bottom: 0.7rem;
    p {
      padding: 0;
    }
    ul,
    ol {
      padding-top: 0;
      padding-bottom: 0;
    }
    li {
      line-height: 200%;
    }
  }
  blockquote {
    font-style: italic;
  }

  table {
    padding-top: 1.5rem;
    border-collapse: collapse;
    margin: 1.5rem 0;
  }

  th {
    text-align: left;
  }
  td,
  th {
    padding: 0.5rem 0.5rem;
    border-top: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd;
  }

  ${Media.tablet`
      h1 {
        font-size: 200%;
      }
      h2 {
        font-size: 135%;
        padding: 1rem 0 0.7rem 0;
      }
  `}
  overflow: auto;

  .note {
    background: #fffbaa;
    display: flex;
    border-radius: 0.5rem;
    margin: 3rem 0;
    position: relative;
    flex-flow: column;
    box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
      0 2px 6px 2px rgba(60, 64, 67, 0.15);

    h4 {
      font-size: 130%;
      font-family: ${({ theme }) => theme.headerFont};
      padding: 1rem;
      margin: 0;
      :before {
        content: '';
      }
    }
    p {
      padding: 1rem;
    }

    :before {
      display: block;
      top: 0;
      left: 0;
      border-radius: 0.5rem 0.5rem 0 0;
      background: #ffa50073;
      padding: 0.3rem 1rem;

      content: 'Note';
    }
    pre {
      margin: 1rem;
    }
  }
`
export default Content
