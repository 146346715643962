import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import Media from './Media'
import { FancyLinks } from './RootStyle'
import { GoBook as TocIcon, GoChevronLeft as CloseIcon } from 'react-icons/go'
import scrollTo from 'gatsby-plugin-smoothscroll'

const TOCLevel1 = css`
  font-weight: ${({ theme }) => theme.header.fontWeight};
  font-family: ${({ theme: { subheaderFont, headerFont, headlineFont } }) =>
    subheaderFont || headlineFont || headerFont};
  font-size: 130%;
  line-height: 180%;
  margin-bottom: 0.5rem;

  ${Media.tablet`
    line-height: 200%;
  `}
`

const MobileButton = styled.button`
  display: none;
  cursor: pointer;

  ${Media.tablet`
    display:block; 
  `}
`

const ShowButton = styled(MobileButton)`
  position: fixed;
  top: 0.2rem;
  left: 0.2rem;
  padding: 0.2rem;
  padding-right: 0.3rem;
  z-index: 100000;
  background: black;
  color: white;
  border: 0;
  margin: 0;
  transition: all ease 0.5s;

  :hover {
    opacity: 0.5;
  }
  .caption {
    float: right;
    padding: 0.3rem;
  }
`

const CloseButton = styled(MobileButton)`
  background: black;
  color: white;
  border: 0;
  :hover {
    opacity: 0.8;
  }
  padding: 0;

  ${Media.tablet`
    display: block;
    position: absolute;
    width: 40px;
    right: 0;
    top:0;
    height: 100vh;
  `}
`

const openWidth = width => ({ shown }) => (shown ? width : 0)

const calcDelay = ({ shown }) => (shown ? 0 : '0.4s')

const TOCOverlay = styled.aside`
  display: flex;
  position: relative;
  background: rgb(255, 255, 255);
  flex: 0 1 300px;
  height: 100%;
  transition: all ease-in-out 0.3s;
  transition-delay: ${calcDelay};
  border-right: 1px solid #dbdbdb;
  border-radius: 0;
  overflow: auto;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 2px 6px 2px rgba(60, 64, 67, 0.15);

  h2 {
    background: black;
    color: white;
    padding: 0.26rem 0.5rem;
    display: none;
  }

  ${Media.largeDesktop`
    flex: 0 1 400px; 
  `}

  ${Media.tablet`

    h2 {
      display: block;
    }

    /* overflow: ${({ shown }) => (shown ? 'hidden' : 'scroll')}; */
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    max-height: 100%;
    z-index: 100000;
    height: 100%;
    /* opacity: ${({ shown }) => (shown ? 1 : 0)}; */
    border: 0;
    border-radius: 0 10px 10px 0;
    max-width: ${openWidth('400px')};
  `}

  ${Media.phone`
    flex: initial;
    border-radius: 0;
    max-width: ${openWidth('100vw')};
  `}
`

const TocContainer = styled.div`
  padding: 1.5rem;
  & > ul > li > p {
    ${TOCLevel1}
  }
  & > ul > li > a {
    ${TOCLevel1}
  }

  & > ul > li {
    list-style-type: none;
    &:not(:first-of-type) {
      margin-left: 0;
      margin-top: 1.5rem;
    }
  }

  & > ul > li > ul {
    padding-left: 1.5rem;
  }

  ${FancyLinks}

  ul {
    padding-left: 0;
  }

  li {
    font-family: ${({
      theme: { bodyFont, subheaderFont, headerFont, headlineFont },
    }) => bodyFont || subheaderFont || headlineFont || headerFont};
    font-weight: 400;
    font-size: 1rem;

    & > a {
      padding: 0.2rem;
      line-height: 210%;
      text-decoration: none;
    }
    p {
      padding: 0;
    }
    line-height: 180%;
  }
`

const TocHeader = styled.h2`
  display: flex;
  flex-flow: row nowrap;
  span {
    margin-left: 1rem;
  }

  ${Media.tablet`
    width: 400px;
    min-width: 400px;
  `}

  ${Media.phone`
    width: 400px;
    min-width: 400px;
  `}
`

const TocScroll = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;

  ${Media.tablet`
    width: 360px;
    min-width: 360px;
  `}
  ${Media.phone`
    width: 100vw;
    min-width: 100vw;
  `}
`
const TableOfContents = ({ tableOfContents }) => {
  const [shown, setShown] = useState(false)

  const showOverlay = () => {
    setShown(true)
  }
  const closeOverlay = () => {
    setShown(false)
  }
  const closeOverlayOverlay = e => {
    const {
      target: { tagName, attributes },
    } = e

    if (tagName.toLowerCase() === 'a') {
      const href = attributes.item('href')
      const id = href.value.split('#')[1]
      scrollTo(`#${id}`)
      setShown(false)
      e.preventDefault()
    }
  }
  return (
    <>
      <ShowButton aria-label="Table of Contents" onClick={showOverlay}>
        <TocIcon size="1.5rem" title="Table of Contents" />
        <div className="caption">TOC</div>
      </ShowButton>
      <TOCOverlay
        {...{ shown }}
        onClick={closeOverlayOverlay}
        data-target="toc"
      >
        <TocScroll {...{ shown }}>
          <TocHeader>
            <TocIcon size="2rem" />
            <span>Table of Contents</span>
          </TocHeader>
          <TocContainer dangerouslySetInnerHTML={{ __html: tableOfContents }} />
        </TocScroll>
        <CloseButton
          onClick={closeOverlay}
          aria-label="Close Table of Contents"
        >
          <CloseIcon size="1.5rem" title="Close" />
        </CloseButton>
      </TOCOverlay>
    </>
  )
}

export default TableOfContents
