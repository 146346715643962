import React from 'react'
import PostMetadata from './PostMetadata'
import styled from 'styled-components'
import Media from './Media'
import Img from 'gatsby-image'
import Share from './Share'
import { HeadingSize } from './Content'
import UnsplashAttribution from './UnsplashAttribution'
import { SectionWithEnd } from './RootStyle'

const PostHeaderContainer = styled.header`
  padding: 0 1rem;
  ${HeadingSize}
  h1 {
    font-size: 2.6rem;
    margin-bottom: 1rem;
  }
  ${Media.phone`
    h1 {
      font-size: 2rem;
    }
  `}
  ${SectionWithEnd}
`

const HeadingImage = styled(Img)`
  margin-top: 0.3rem;
  margin-top: 1rem;
`

const BellowImage = styled.div`
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;

  ${Media.phone`
    flex-flow: column; 
  `}
`

export const PostHeader = ({
  title,
  date,
  tags,
  fluid,
  url,
  unsplash: { alt, ...unsplash },
}) => (
  <PostHeaderContainer>
    <h1>{title}</h1>
    <PostMetadata {...{ date, tags }} />
    <HeadingImage {...{ fluid, alt }} />
    <BellowImage>
      <UnsplashAttribution {...unsplash} />
      <Share {...{ url }} />
    </BellowImage>
  </PostHeaderContainer>
)
