import React from 'react'
import styled from 'styled-components'
import Media from './Media'
import { FancyLinks } from './RootStyle'

const Container = styled.div`
  display: flex;
  align-items: top;
  font-size: 80%;
  ${Media.phone`
    font-size: 60%;
    margin-bottom: 1rem; 
  `}

  ${FancyLinks}
`

const UnsplashAttribution = ({ url, author }) => (
  <Container>
    <span>
      Photo by <a href={url}>{author}</a> on{' '}
      <a href="//unsplash.com">Unsplash</a>
    </span>
  </Container>
)
export default UnsplashAttribution
