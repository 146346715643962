import React from 'react'
import styled from 'styled-components'
import SEO from '../components/SEO'
import Content from '../components/Content'
import 'font-awesome/css/font-awesome.css'
import { graphql } from 'gatsby'
import LayoutWithQuery from '../components/Layout'
import { PostHeader } from '../components/PostHeader'
import Share from '../components/Share'
import { SectionWithEnd } from '../components/RootStyle'
import TableOfContents from '../components/TableOfContents'
import Footer from '../components/Footer'
import Media from '../components/Media'

const Markdown = styled.div`
  margin-top: 3rem;
  padding: 0 1rem;

  h2 {
    font-size: 200%;
    border-bottom: 1px solid #dbdbdb;
    margin-bottom: 2rem;
    margin-top: 3rem;
  }
  h3 {
    font-size: 150%;
    /* font-weight: ${({ theme }) => theme.header.fontWeight}; */
    /* font-weight: 600;  */
    margin-top: 5rem;
    margin-bottom: 0.5rem;
  }
  h4 {
  margin-top: 0.5rem;
  }

  .gatsby-resp-image-wrapper {
    box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
      0 2px 6px 2px rgba(60, 64, 67, 0.15);
  }

  a.gitlab-link {
    margin: 1rem 0;
    display: block;
    padding: 0.5rem;
    text-align: center;
    border: 2px dotted rgb(107, 161, 255);
    font-weight: ${({ theme }) => theme.header.fontWeight};
    font-family: ${({ theme }) => theme.headlineFont};

    img {
      padding-top: 1rem;
      padding-bottom: 0;
      margin: 0;
    }

    :hover {
      border: 2px solid;
    }
  }
`

const Layout = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  flex: 1 0;
`
const ShareAside = styled.aside`
  ${SectionWithEnd}
  border-top: 1px solid #dbdbdb;
  margin: 5rem 1rem 0 1rem;
`

const PostContent = styled.div`
  width: 100%;
  overflow: auto;
  flex: 1 0;
`

const Container = styled.div`
  max-width: ${({ theme }) => theme.maxwidth};
  padding-top: 1.5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  ${Media.tablet`
    padding-left: 0;
    padding-right: 0;
  `}
  footer {
    padding: 0 1rem;
  }
`

const Post = ({
  data: {
    markdownRemark: {
      html,
      tableOfContents,
      excerpt,
      frontmatter: {
        title,
        date,
        tags,
        dateSEO,
        banner: {
          src: {
            childImageSharp: { fluid },
          },
          unsplash,
        },
      },
      fields: { slug },
    },
    site: {
      siteMetadata: { siteName, url: homeUrl, social, author },
      pathPrefix,
    },
  },
  url = `${homeUrl}${pathPrefix}${slug}`,
}) => (
  <LayoutWithQuery isPost={true}>
    <SEO
      isPost
      date={dateSEO}
      publisher={siteName}
      description={excerpt}
      image={`${homeUrl}${fluid.src}`}
      {...{ url, title, author, social, siteName }}
    />

    <Layout>
      <TableOfContents {...{ tableOfContents }} />
      <PostContent>
        <Container>
          <PostHeader {...{ title, date, tags, fluid, url, unsplash }} />
          <Content>
            <Markdown dangerouslySetInnerHTML={{ __html: html }} />
            <ShareAside>
              <Share {...{ url }} />
            </ShareAside>
          </Content>
          <Footer />
        </Container>
      </PostContent>
    </Layout>
  </LayoutWithQuery>
)

export default Post

export const query = graphql`
  query BlogPostQuery($slug: String!) {
    site {
      siteMetadata {
        title
        siteName
        url
        social {
          gravatar
          twitter
        }
        author {
          name
          email
        }
      }
      pathPrefix
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      tableOfContents(maxDepth: 3)
      html
      excerpt
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        dateSEO: date(formatString: "YYYY-MM-DD")
        tags
        banner {
          src {
            childImageSharp {
              fluid(maxWidth: 1024, maxHeight: 600) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          unsplash {
            url
            author
            alt
          }
        }
      }
      fields {
        slug
      }
    }
  }
`
