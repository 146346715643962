import React from 'react'
import styled from 'styled-components'
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
  RedditShareButton,
  RedditIcon,
} from 'react-share'

const ShareContainer = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: right;
  padding: 8px 0;

  .shareText {
    padding: 0 0.7rem 0 0;
  }

  .shareButton {
    transition: all ease-out 0.4s;
    cursor: pointer;
    :hover {
      opacity: 0.7;
    }
    :not(:last-of-type) {
      margin-right: 5px;
    }
  }
`

const Share = ({ url }) => (
  <ShareContainer>
    <div className="shareText">Share post</div>
    <FacebookShareButton className="shareButton" url={url}>
      <FacebookIcon size={30} round={true} />
    </FacebookShareButton>
    <TwitterShareButton className="shareButton" url={url}>
      <TwitterIcon size={30} round={true} />
    </TwitterShareButton>
    <LinkedinShareButton className="shareButton" url={url}>
      <LinkedinIcon size={30} round={true} />
    </LinkedinShareButton>
    <RedditShareButton className="shareButton" url={url}>
      <RedditIcon size={30} round={true} />
    </RedditShareButton>
  </ShareContainer>
)

export default Share
